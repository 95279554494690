var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":"Add Gallery","items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"forum",attrs:{"enctype":"multipart/form-data"}},[_vm._l((_vm.form.clinic_gallery),function(item,index){return _c('div',{key:item.id},[_c('b-form-group',[_c('label',[_vm._v("Clinic Image "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"row pl-2"},[_c('div',{staticClass:"col-7 pl-0"},[_c('b-form-file',{ref:"fileInput_${index}",refInFor:true,class:{
                        'is-invalid':
                          _vm.submitted &&
                          !_vm.$v.form.clinic_gallery.$each[index].image.$model,
                      },attrs:{"accept":".jpg,.png,.svg","placeholder":"Choose a file or drop it here"},on:{"change":function($event){return _vm.handleFileChange($event, index)}}}),(
                        _vm.submitted &&
                        !_vm.$v.form.clinic_gallery.$each[index].image.$model
                      )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Image is required. ")]):_vm._e(),(item.previewUrl)?_c('div',{staticClass:"image-preview"},[_c('img',{staticClass:"mt-2",attrs:{"src":item.previewUrl,"alt":"Image preview","width":"128px","height":"128px"}})]):_vm._e()],1),_c('div',{staticClass:"col-4 socialLinks",staticStyle:{"align-items":"start"}},[(_vm.form.clinic_gallery.length - 1 == index)?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.form.clinic_gallery[index].image == 0},on:{"click":function($event){$event.preventDefault();return _vm.addImage(index)}}},[_vm._v(" Add Image ")]):_vm._e(),_c('b-button',{attrs:{"variant":"danger","disabled":_vm.form.clinic_gallery.length <= 0},on:{"click":function($event){return _vm.removeImage(index)}}},[_vm._v(" Remove Image ")])],1),_c('b-form-group',{staticClass:"col-1 d-flex align-items-start mt-2"},[_c('b-form-checkbox',{model:{value:(_vm.form.clinic_gallery[index].is_active),callback:function ($$v) {_vm.$set(_vm.form.clinic_gallery[index], "is_active", $$v)},expression:"form.clinic_gallery[index].is_active"}},[_vm._v(" Active ")])],1)],1)])],1)}),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-clinic-gallery')?_c('span',[_vm._v(" Save Data ")]):_c('span',[_vm._v(" Update Data ")])])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }