<template>
  <Layout>
    <PageHeader title="Add Gallery" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <div v-for="(item, index) in form.clinic_gallery" :key="item.id">
                <b-form-group>
                  <label>Clinic Image <span style="color: red">*</span></label>
                  <div class="row pl-2">
                    <div class="col-7 pl-0">
                      <b-form-file
                        accept=".jpg,.png,.svg"
                        @change="handleFileChange($event, index)"
                        ref="fileInput_${index}"
                        placeholder="Choose a file or drop it here"
                        :class="{
                          'is-invalid':
                            submitted &&
                            !$v.form.clinic_gallery.$each[index].image.$model,
                        }"
                      >
                      </b-form-file>
                      <div
                        v-if="
                          submitted &&
                          !$v.form.clinic_gallery.$each[index].image.$model
                        "
                        class="invalid-feedback"
                      >
                        Image is required.
                      </div>
                      <div v-if="item.previewUrl" class="image-preview">
                        <img
                          :src="item.previewUrl"
                          alt="Image preview"
                          width="128px"
                          height="128px"
                          class="mt-2"
                        />
                      </div>
                    </div>
                    <div class="col-4 socialLinks" style="align-items: start">
                      <b-button
                        variant="primary"
                        v-if="form.clinic_gallery.length - 1 == index"
                        @click.prevent="addImage(index)"
                        :disabled="form.clinic_gallery[index].image == 0"
                      >
                        Add Image
                      </b-button>
                      <b-button
                        variant="danger"
                        @click="removeImage(index)"
                        :disabled="form.clinic_gallery.length <= 0"
                      >
                        Remove Image
                      </b-button>
                    </div>
                    <b-form-group class="col-1 d-flex align-items-start mt-2">
                      <b-form-checkbox
                        v-model="form.clinic_gallery[index].is_active"
                      >
                        Active
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
              >
                <span v-if="this.$route.name == 'add-clinic-gallery'">
                  Save Data
                </span>
                <span v-else> Update Data </span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import { required } from "vuelidate/lib/validators";
import hospitalMixin from "@/mixins/ModuleJs/clinic-gallery";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, hospitalMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      clinic_gallery: {
        $each: {
          image: { required },
        },
      },
    },
  },
};
</script>
